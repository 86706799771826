<template>
  <span
    class="px-4 py-2 rounded-md text-xs bg-opacity-100"
    :class="{
      'bg-primary text-white': type === 'primary',
      'bg-gray-light text-white': type === 'secondary',
      'bg-error-lightest text-error': type === 'error',
      'bg-success-lightest text-success': type === 'success',
      'bg-warning-lightest text-warning': type === 'warning',
      'bg-tertiary-lightest text-tertiary-dark': type === 'blue',
      'bg-secondary-lightest text-secondary-dark': type === 'purple',
      'bg-primary-tosca-lightest text-primary-tosca-dark': type === 'tosca',
      'bg-gray-lightest text-gray-dark': type === 'gray',
      'shadow-primary-sm': ['primary', 'danger', 'success'].includes(type),
      'shadow-primary-sm': type !== 'secondary' && type !== 'warning',
    }"
  >
    <slot />
  </span>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "primary",
    },
  },
  name: "LabelGista",
};
</script>
