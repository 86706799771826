<template>
  <div class="relative text-lg w-full">
    <button
      class="flex items-center justify-between px-3 py-2 w-full rounded-md"
      :class="{
        'item-disabled': disabled,
        'bg-white border border-primary':
          !disabled && (color == undefined || color == 'primary'),
        'bg-secondary border border-secondary text-white':
          !disabled && color == 'secondary',
        'bg-tertiary border border-tertiary text-white':
          !disabled && color == 'tertiary',
        custom_class,
      }"
      @click="
        disabled
          ? (isOptionsExpanded = false)
          : (isOptionsExpanded = !isOptionsExpanded)
      "
      @blur="isOptionsExpanded = false"
    >
      <span class="text-sm">{{ selectedLabel }}</span>
      <svg
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        class="h-4 w-4 transform transition-transform duration-200 ease-in-out"
        :class="isOptionsExpanded ? 'rotate-180' : 'rotate-0'"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </button>
    <transition
      enter-active-class="transform transition duration-500 ease-custom"
      enter-class="-translate-y-1/2 scale-y-0 opacity-0"
      enter-to-class="translate-y-0 scale-y-100 opacity-100"
      leave-active-class="transform transition duration-300 ease-custom"
      leave-class="translate-y-0 scale-y-100 opacity-100"
      leave-to-class="-translate-y-1/2 scale-y-0 opacity-0"
    >
      <ul
        v-show="isOptionsExpanded"
        class="absolute left-0 right-0 mb-4 rounded-md overflow-hidden bg-white z-50"
        :class="{
          'shadow-primary-sm':
            !disabled && (color == undefined || color == 'primary'),
          'shadow-secondary-sm': !disabled && color == 'secondary',
          'shadow-tertiary-sm': !disabled && color == 'tertiary',
        }"
      >
        <slot />
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  // DON'T USE THIS COMPONENT
  props: {
    disabled: Boolean,
    color: String,
    selected: {
      type: String,
      default: "choose",
    },
    selectedLabel: {
      type: String,
      default: "Choose",
    },
  },
  name: "DropdownGista",
  data() {
    return {
      isOptionsExpanded: false,
    };
  },
  methods: {
    selectItem(value, label) {
      this.selected = value;
      this.selectedLabel = label;
      this.$emit("update:selected", value);
      this.$emit("update:selected-label", label);
    },
  },
};
</script>

<style>
.ease-custom {
  transition-timing-function: cubic-bezier(0.61, -0.53, 0.43, 1.43);
}
.item-disabled {
  color: #aaaaaa;
  background-color: #e9ecef;
  cursor: not-allowed;
}
</style>
