var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative text-lg w-full"},[_c('button',{staticClass:"flex items-center justify-between px-3 py-2 w-full rounded-md",class:{
      'item-disabled': _vm.disabled,
      'bg-white border border-primary':
        !_vm.disabled && (_vm.color == undefined || _vm.color == 'primary'),
      'bg-secondary border border-secondary text-white':
        !_vm.disabled && _vm.color == 'secondary',
      'bg-tertiary border border-tertiary text-white':
        !_vm.disabled && _vm.color == 'tertiary',
      custom_class: _vm.custom_class,
    },on:{"click":function($event){_vm.disabled
        ? (_vm.isOptionsExpanded = false)
        : (_vm.isOptionsExpanded = !_vm.isOptionsExpanded)},"blur":function($event){_vm.isOptionsExpanded = false}}},[_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(_vm.selectedLabel))]),_c('svg',{staticClass:"h-4 w-4 transform transition-transform duration-200 ease-in-out",class:_vm.isOptionsExpanded ? 'rotate-180' : 'rotate-0',attrs:{"fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M19 9l-7 7-7-7"}})])]),_c('transition',{attrs:{"enter-active-class":"transform transition duration-500 ease-custom","enter-class":"-translate-y-1/2 scale-y-0 opacity-0","enter-to-class":"translate-y-0 scale-y-100 opacity-100","leave-active-class":"transform transition duration-300 ease-custom","leave-class":"translate-y-0 scale-y-100 opacity-100","leave-to-class":"-translate-y-1/2 scale-y-0 opacity-0"}},[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOptionsExpanded),expression:"isOptionsExpanded"}],staticClass:"absolute left-0 right-0 mb-4 rounded-md overflow-hidden bg-white z-50",class:{
        'shadow-primary-sm':
          !_vm.disabled && (_vm.color == undefined || _vm.color == 'primary'),
        'shadow-secondary-sm': !_vm.disabled && _vm.color == 'secondary',
        'shadow-tertiary-sm': !_vm.disabled && _vm.color == 'tertiary',
      }},[_vm._t("default")],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }