<template>
  <div class="bg-white p-6 rounded-2xl shadow-gray-sm">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Card",
};
</script>
