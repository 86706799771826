var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative"},[_c('div',{class:("flex flex-col justify-center h-12 px-2 rounded-lg " + (_vm.disabled
        ? 'bg-gray-lightest text-gray-darkest cursor-not-allowed'
        : 'bg-white') + " border " + (_vm.isOptionsExpanded ? 'border-secondary' : 'border-primary'))},[_c('div',{staticClass:"relative text-sm w-full"},[_c('button',{staticClass:"flex items-center justify-between w-full text-lg rounded-md",class:{
          'bg-gray-lightest text-gray-darkest cursor-not-allowed': _vm.disabled,
          ' ': !_vm.disabled && !_vm.noBorder,
          '': _vm.error,
          '': !_vm.noBorder && !_vm.disabled,
          'text-base': _vm.size != 'large',
          'h-12': (_vm.size = 'large'),
        },on:{"click":function($event){_vm.disabled
            ? (_vm.isOptionsExpanded = false)
            : (_vm.isOptionsExpanded = !_vm.isOptionsExpanded)},"blur":function($event){_vm.isOptionsExpanded = false}}},[(_vm.iconLeft)?_c('div',{staticClass:"absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"},[_c('span',{class:_vm.iconLeft})]):_vm._e(),_c('div',[_c('label',{staticClass:"flex items-center text-sm text-gray",class:{ 'text-error': _vm.error, 'cursor-not-allowed': _vm.disabled }},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('p',{staticClass:"text-base",class:{
              'pl-6': _vm.iconLeft,
              'cursor-not-allowed': _vm.disabled,
              'text-gray-light': _vm.value === '' || _vm.value === undefined,
              'text-gray': _vm.value !== '',
            }},[_vm._t("selected",function(){return [_vm._v(_vm._s(_vm.selected || _vm.placeholder))]},{"slotProps":_vm.selected})],2)]),_c('svg',{staticClass:"h-4 w-4 transform transition-transform duration-200 ease-in-out",class:!_vm.readOnly && _vm.isOptionsExpanded ? 'rotate-180' : 'rotate-0',attrs:{"fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M19 9l-7 7-7-7"}})])])])]),_c('transition',{attrs:{"enter-active-class":"transform transition duration-500 ease-custom","enter-class":"-translate-y-1/2 scale-y-0 opacity-0","enter-to-class":"translate-y-0 scale-y-100 opacity-100","leave-active-class":"transform transition duration-300 ease-custom","leave-class":"translate-y-0 scale-y-100 opacity-100","leave-to-class":"-translate-y-1/2 scale-y-0 opacity-0"}},[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(!_vm.readOnly && _vm.isOptionsExpanded),expression:"!readOnly && isOptionsExpanded"}],staticClass:"absolute left-0 right-0 mb-4 rounded-md overflow-hidden bg-white z-50",class:{
        'shadow-primary-sm':
          !_vm.disabled && (_vm.color == undefined || _vm.color == 'primary'),
        'shadow-secondary-sm': !_vm.disabled && _vm.color == 'secondary',
        'shadow-tertiary-sm': !_vm.disabled && _vm.color == 'tertiary',
      }},_vm._l((_vm.options),function(v,i){return _c('li',{key:i,staticClass:"px-3 py-2 transition-colors duration-100 text-gray hover:bg-secondary hover:text-white",class:_vm.isActive ? 'bg-secondary text-white' : 'text-gray',attrs:{"value":typeof v === 'object' ? v.value : v,"label":_vm.label},on:{"click":function($event){return _vm.setOption(v)}}},[_vm._t("option",function(){return [_vm._v(_vm._s(typeof v === "object" ? v.text : v))]},{"slotProps":v})],2)}),0)]),(_vm.hint && !_vm.dense)?_c('div',{staticClass:"text-xs text-gray mt-1"},[_vm._v(" "+_vm._s(_vm.hint)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }