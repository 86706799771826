<template>
  <div
    class="fixed h-screen p-5 shadow-primary-lg"
    :class="[
      {
        'left-0': position === 'left',
        'right-0': position === 'right',
        'bg-white': !customClass,
      },
      computedWidth,
      customClass,
    ]"
  >
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  // DON'T USE THIS IN PROJECT (AKAN DIPINDAH KE NEWBI)
  name: "SidemenuGista",
  props: {
    position: {
      type: String,
      default: "left",
    },
    width: {
      type: String,
      default: "1/5",
    },
    customClass: {
      type: String,
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  computed: {
    mobileView() {
      this.$emit("mobile-view", this.windowWidth < 640);
      return this.windowWidth < 640;
    },
    computedWidth() {
      return `w-${this.width}`;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>
