<template>
  <div
    v-if="show"
    class="notification fixed bottom-28 right-4 w-11/12 flex justify-center z-20"
  >
    <div
      class="notification-container bg-white w-full mx-auto rounded-lg shadow-primary-sm z-50 overflow-y-auto"
    >
      <div
        class="notification-content items-center py-3 px-4 text-center"
        :class="{
          'bg-primary-dark': type === 'success',
          'bg-error': type === 'error',
        }"
      >
        <div
          class="flex flex-col w-full justify-center relative text-white"
          :class="{
            'bg-primary-dark': type === 'success',
            'bg-error': type === 'error',
          }"
        >
          <span class="absolute inset-y-0 left-0 flex items-center ml-4">
            <svg
              width="24"
              height="21"
              viewBox="0 0 24 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              v-if="type === 'error'"
            >
              <path
                d="M10.2888 2.86118L1.8188 17.0012C1.64417 17.3036 1.55177 17.6465 1.55079 17.9957C1.54981 18.3449 1.64029 18.6883 1.81323 18.9917C1.98616 19.2951 2.23553 19.5479 2.53651 19.725C2.83749 19.9021 3.1796 19.9973 3.5288 20.0012H20.4688C20.818 19.9973 21.1601 19.9021 21.4611 19.725C21.7621 19.5479 22.0114 19.2951 22.1844 18.9917C22.3573 18.6883 22.4478 18.3449 22.4468 17.9957C22.4458 17.6465 22.3534 17.3036 22.1788 17.0012L13.7088 2.86118C13.5305 2.56729 13.2795 2.3243 12.98 2.15567C12.6805 1.98703 12.3425 1.89844 11.9988 1.89844C11.6551 1.89844 11.3171 1.98703 11.0176 2.15567C10.7181 2.3243 10.4671 2.56729 10.2888 2.86118V2.86118Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 8V12"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 16H12.01"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <svg
              width="18"
              height="13"
              viewBox="0 0 18 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              v-if="type === 'success'"
            >
              <path
                d="M17 1L6 12L1 7"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <slot>Toast Message</slot>
          <span
            class="absolute inset-y-0 right-0 flex items-center mr-4"
            @click="close"
            v-if="showClose"
          >
            <svg class="w-4 h-4 fill-current" role="button" viewBox="0 0 20 20">
              <path
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
                fill-rule="evenodd"
              ></path>
            </svg>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // DON'T USE THIS IN PROJECT (AKAN DIPINDAH KE NEWBI)
  props: {
    type: {
      type: String,
      default: "success",
    },
    show: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: false,
    },
  },
  name: "ToastGista",
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
