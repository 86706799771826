var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.show)?_c('div',{staticClass:"fixed inset-0 w-full flex items-center justify-center"}):_vm._e(),(_vm.show)?_c('div',{staticClass:"absolute z-100 bg-white rounded-md p-4",style:({
      width: _vm.width,
      top: _vm.top,
      left: _vm.left,
    })},[_c('div',{staticClass:"absolute z-90 w-4 h-4 transform rotate-45 bg-white -top-2 right-20"}),_vm._t("default")],2):_vm._e(),(_vm.show)?_c('div',{staticClass:"box-content absolute z-90 opacity-100",style:([
      _vm.helper,
      {
        boxShadow: 'rgb(33 33 33 / 40%) 0px 0px 0px 5000px',
      } ])}):_vm._e(),(_vm.show)?_c('div',{staticClass:"box-content absolute z-100",style:([_vm.helper, { backgroundColor: 'transparent' }])}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }